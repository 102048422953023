<template>
  <GenericSideMenu
    position="right"
    class="MiniCart"
    content-class="!p-0"
    :title="`${$t('minicart.headline')}`"
    :title-meta="cartItems"
    :title-description="titleDescription"
    @close="close"
  >
    <LoadBasketById v-if="userStore.isCustomerSuccessUser || userStore.isSalesRepUser" />

    <div
      v-if="isOrderProposalBasket"
      class="transition-all flex-wrap w-full type-headline-xxs relative z-header desk:flex-nowrap mobOnly:max-h-46 desk:max-h-32 px-16 py-[10px] bg-brightYellow type-headline-xxs text-darkest flex justify-center"
    >
      <div class="flex flex-col justify-center items-center desk:flex-row">
        <div v-if="orderProposalId" class="flex ml-12">
          {{ $t('siteNotice.editingOrderProposal') }} #{{ orderProposalId }} | <span class="underline cursor-pointer ml-4" @click="clearOrderProposalForSalesRep()">{{ $t('checkout.orderProposal.cancel') }}</span>
        </div>
        <div v-else class="flex ml-12 gap-8">
          {{ $t('siteNotice.editingOrderProposalCustomer') }}  | <span class="underline cursor-pointer" @click="clearOrderProposalForCustomer()">{{ $t('checkout.orderProposal.cancel') }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="cartStore.items.length"
      class="p-12 desk:p-24"
      :class="{ 'pt-24': orderProposalId }"
    >

      <div>
        <div class="mb-4 type-xs-medium desk:type-sm-medium">
          {{ freeShippingProgress < 1 ? $t('minicart.progressbar.left', {sum: useLocalizedCurrency(leftToFreeShipping)}): $t('minicart.progressbar.full') }}
        </div>
        <div class="border border-darkest h-4">
          <div
            class="h-full"
            :class="{
              'bg-brightGreen': freeShippingProgress === 1,
              'bg-darkest': freeShippingProgress !== 1
            }"
            :style="`width: ${freeShippingProgress * 100}%`"
          />
        </div>
      </div>
      <CrossdockingBanner v-if="isBackorderBasket" :expanded="false" />
      <!-- Hide this for now
      <button class="btn btn&#45;&#45;secondary w-full mt-12" @click="uiStore.setShowScanner(true)">
        <span class="normal-case mr-8">{{ $t('minicart.barcode.scan') }}</span>
        <img
          alt=""
          src="/icons/barcode-read.svg"
          class="w-16 h-16 relative -top-1"
        >
      </button>-->
      <MiniCartProduct
        v-for="(item, index) in cartStore.items"
        :key="`${item.lineNo}-${index}-${item.quantity}-${cartItems}`"
        :item="item"
        :hide-cross-docking="!globalContent.getAllowBackorders || userStore.isSalesRepUser || userStore.isCustomerSuccessUser || cartStore.items.length < 2"
      />
      <div
        class="flex flex-col gap-12 desk:gap-16 py-12 desk:py-16 type-xs-medium desk:type-sm-medium leading-single"
        :class="{
          'opacity-50': cartStore.isLoading
        }"
      >
        <div v-if="showPoints" class="flex justify-between">
          <div class="flex items-center">
            <div>
              {{ $t('minicart.summary.points') }}
            </div>
            <Tooltip class="ml-6" :text="$t('minicart.summary.pointsInfo', {amount: globalContent.getPointCollectingThreshold})">
              <img
                class="w-12 h-12 desk:w-16 desk:h-16 -translate-y-1"
                src="/icons/info.svg"
              >
            </Tooltip>
          </div>
          <div class="pl-16 text-right">
            {{ `${cartStore.cart.summary.points} ${$t('minicart.summary.pointsSuffix')}` }}
          </div>
        </div>
        <div v-if="showPoints" class="color-light h-1 w-full" />
        <div
          v-for="fee in cartStore.cart.summary?.additionalFees"
          :key="fee.name"
          class="flex justify-between"
          >
          <div>{{ fee.name }}</div>
          <div class="pl-16 font-medium text-right">
            {{ fee.feeBeforeVatDisplay }}
          </div>
        </div>
        <div class="flex justify-between">
          <div>{{ $t('minicart.summary.sum') }}</div>
          <div class="pl-16 text-right">
            {{ cartStore.cart.summary?.subtotalBeforeVatDisplay }}
          </div>
        </div>
        <div class="flex justify-between">
          <div>{{ $t('minicart.summary.freight') }}</div>
          <div class="pl-16 text-right">
            <span>{{ cartStore.cart.summary?.shippingBeforeVatDisplay }}</span>
          </div>
        </div>
        <div v-if="cartStore.cart.summary?.crossDockingShippingBeforeVat" class="flex justify-between">
          <div>{{ $t('crossDocking.shipping') }}</div>
          <div class="pl-16 text-right">
            <span>{{ cartStore.cart.summary?.crossDockingShippingBeforeVatDisplay }}</span>
          </div>
        </div>
        <div v-if="cartStore.cart.summary.vatDisplay" class="flex justify-between">
          <div>{{ $t('minicart.summary.vat') }}</div>
          <div class="pl-16 text-right">
            ({{ cartStore.cart.summary.vatDisplay.trim() }})
          </div>
        </div>
        <div
          v-if="cartStore.hasDiscount"
          class="flex justify-between"
        >
          <div>{{ $t('minicart.summary.discount') }}</div>
          <div class="pl-16 text-right text-brightRed">
            -{{ cartStore.cart.summary.discountDisplay.trim() }}
          </div>
        </div>
        <div class="color-light h-1 w-full" />
        <div>
          <div class="flex justify-between items-end mb-8 type-headline-sm desk:type-headline-lg leading-single">
            <div>{{ $t('minicart.summary.exVat') }}</div>
            <div class="type-headline-xl desk:type-headline-2xl leading-single pl-16 text-right">
              <LoadingIcon v-if="cartStore.isLoading" class="relative -top-2" />
              <span v-else>{{ cartStore.cart.summary.priceBeforeVatDisplay }}</span>
            </div>
          </div>
          <div class="flex justify-between items-end text-dark">
            <div>{{ $t('minicart.summary.incVat') }}</div>
            <div class="pl-16 text-right">({{ cartStore.cart.summary.priceDisplay.trim() }})</div>
          </div>
        </div>
        <nuxt-link
          :to="manualUrl('/checkout')"
          class="btn btn--lg w-full btn--confirm"
          :class="{
            'loading': cartStore.isLoading
          }"
        >
          {{ $t('minicart.toCheckout') }}
          <img
            src="/icons/arrow-right.svg"
            class="inline-block w-20 h-20 ml-12"
          >
        </nuxt-link>
      </div>
    </div>
    <div v-else class="p-24 h-full flex w-full items-center justify-center">
      <div class="relative -top-88 type-sm-medium">
<!--        Hide this for now-->
<!--        <button class="btn btn&#45;&#45;secondary w-full mt-12" @click="uiStore.setShowScanner(true)">
          <span class="normal-case mr-8">{{ $t('minicart.barcode.scan') }}</span>
          <img
            alt=""
            src="/icons/barcode-read.svg"
            class="w-16 h-16 relative -top-1"
          >
        </button>-->
        {{ $t('minicart.empty') }}
      </div>
    </div>
  </GenericSideMenu>
</template>

<script setup lang="ts">
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import MiniCartProduct from '~/components/cart/MiniCartProduct.vue';
import { useCartStore } from '~/store/cart';
import { storeToRefs } from 'pinia';

import { useUiStore } from '~/store/ui';
import { createEventFromCart } from '~/composeables/useTracking';

import { useLocalizedCurrency } from '~/composeables/useLocalizedCurrency';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { useGlobalContentStore } from '~/store/globalContent';
import Tooltip from '~/components/body/Tooltip.vue';
import { useUserStore } from '~/store/user';
import LoadingIcon from '~/components/generic/LoadingIcon.vue';
import BarcodeScanner from '~/components/fastOrder/BarcodeScanner.vue';
import LoadBasketById from '~/components/account/LoadBasketById.vue';
import CrossdockingBanner from '~/components/cart/CrossdockingBanner.vue';

const cartStore = useCartStore();
const userStore = useUserStore();
const { cartItems } = storeToRefs(cartStore);
const { $toNumber } = useNuxtApp();
const uiStore = useUiStore();
const globalContent = useGlobalContentStore();

const { manualUrl } = useLinkReplacer();
const close = () => {
  uiStore.setShowMiniCart(false);
};

const emit = defineEmits(['closeMenu']);

const showScanner = ref(false);

onMounted(()=> {
  createEventFromCart('view_cart');
});

const titleDescription = computed(() => {
  if (userStore.isSalesRepUser && cartStore.cart?.norceBasketId) {
    return `#${ cartStore.cart.norceBasketId }`;
  }
  return '';
});

const clearOrderProposalForSalesRep = async() => {
  cartStore.deleteCart();
  uiStore.closeAllModal();
  emit('closeMenu');
  await userStore.setCurrentlyEditingOrderProposal(null);
};

const clearOrderProposalForCustomer = async() => {
  cartStore.deleteCart();
  uiStore.closeAllModal();
};

const freeShippingBreakpoint = globalContent.getFreeShippingThreshold;

const totalPrice = computed(() => {
  return $toNumber(cartStore.cart.summary.priceBeforeVat) - $toNumber(cartStore.cart.summary.shippingBeforeVat);
});

const freeShippingProgress = computed(() => {
  return totalPrice.value < freeShippingBreakpoint ? totalPrice.value / freeShippingBreakpoint : 1;
});

const leftToFreeShipping = computed(() => {
  return freeShippingBreakpoint - totalPrice.value;
});

const isOrderProposalBasket = computed(() => {
  return !!(userStore.isEditingOrderProposal ? userStore.userProfile.currentlyEditingOrderProposal?.norceBasketId : null) || cartStore.isOrderProposalBasket;
});

const isBackorderBasket = computed(() => {
  return cartStore.cart.items.some((item) => item.stockStatus.value < 1);
});

const orderProposalId = computed(() => {
  return userStore.isEditingOrderProposal ? userStore.userProfile.currentlyEditingOrderProposal?.norceBasketId : null;
});

const showPoints = computed(() => {
  return userStore.allowPointsPurchase && globalContent.getPointCollectingThreshold <= totalPrice.value;
});

const addFromBarcodeReader = () => {
  showScanner.value = false;
};
</script>

<style scoped lang="postcss">
</style>
